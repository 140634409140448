import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import Content from '../components/content'
import Sidebar from '../components/Sidebar'
import ContactBg from '../components/normal/ContactBg'
import ContactBox from '../components/normal/ContactBox'

const ContactPage = () => (
  <>
    <Helmet
      title="Contact - Rémi Carayon"
      bodyAttributes={{
        class: 'page',
      }}
      defer={false}
    >
      <meta property="og:title" content="Contact" />
    </Helmet>
    <Layout>
      <Sidebar place="page" />
      <Content>
        <ContactBg />
        <ContactBox />
      </Content>
    </Layout>
  </>
)

export default ContactPage
