import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import ContactBgCSS from './ContactBg.module.css'

const ContactBg = () => (
  <StaticImage
    src="../../inc/img/unplane.jpg"
    alt="Contact Background Image"
    title="Green Unfolded Paper Plane"
    layout="FULL_WIDTH"
    className={ContactBgCSS.bg}
    objectFit="contain"
  />
)

export default ContactBg
