import React from 'react'
import ContactBoxCSS from './ContactBox.module.css'

const ContactBox = () => (
  <div className={ContactBoxCSS.callme}>
    <h4 className={ContactBoxCSS.how}>email:</h4>
    <a className={ContactBoxCSS.theway} href="mailto:remicarayon@gmail.com">
      remicarayon@gmail.com
    </a>

    <h4 className={ContactBoxCSS.how}>insta:</h4>
    <a
      className={ContactBoxCSS.theway}
      href="https://www.instagram.com/remcaphoto/"
    >
      @remcaphoto
    </a>
  </div>
)

export default ContactBox
